export default {

    businessDashboard : [
        { path: "mobileapps/description" , component:() => import("@/modules/mobileapps/components/DashMobileAppsDescription.vue") , props : true },
        { path: "mobileapps/statistics" , component:() => import("@/modules/mobileapps/components/DashMobileAppsStatistics.vue") , props : true },
        { path: "mobileapps/news" , component:() => import("@/modules/mobileapps/components/DashMobileAppsNews.vue") , props : true },
        { path: "mobileapps/iap" , component:() => import("@/modules/mobileapps/components/DashMobileAppsInAppPurchase.vue") , props : true },
    ]
}


