<template>
  <div class="d-flex">
    <v-sheet dark
             :color="wsBACKGROUND"
             style="overflow:hidden; border-radius: 50px"
             height="40" width="40" class="mr-2 d-flex align-center justify-center"
    >
      <v-icon v-if="!item.img">mdi-account-tie</v-icon>
      <v-img v-else :src="item.img" height="40" width="40" />
    </v-sheet>
    <div >
      <div class="d-flex align-center">
        <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5> <slot name="append.name"></slot>
      </div>
      <h5
          v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
          class="font-weight-regular">{{ item.email }}</h5>
    </div>

  </div>
</template>

<script>
export default {
  name: "wsUserData",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    hideContacts: {
      type : Boolean,
      default : false
    }
  }
}
</script>

<style scoped>

</style>