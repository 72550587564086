const actions = {
    async GET_BUSINESS_COURSES_ORDERS({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',{
            token:'token' ,
            route:`orders/courses`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
        },{ root : true });
    },

    async EDIT_BUSINESS_COURSE_ORDER({dispatch,rootState} , body) {
        return await dispatch('ajax/SEND_NEW',{
            token:'token' ,
            route:`order/course/edit`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        },{ root : true });
    },
}
export default {
    namespaced:true,
    actions,
}