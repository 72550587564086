const actions = {


    // Statistics

    async GET_MOBILE_STATS({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/stats`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // SENDING FUNCTIONS

    async GET_MOBILE_FEED({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`feed/mobile`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_MOBILE_FEED_POST({dispatch,rootState} , body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`feed/mobile/post`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // IN APP PRICES

    async GET_MOBILE_IN_APP_PRODUCTS({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/iap/products`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_EDIT_MOBILE_IN_APP_PRODUCT({dispatch,rootState},body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/iap/product`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },

    async EDIT_COURSE_MOBILE_PRICE({dispatch,rootState},body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/iap/edit_course`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },

    async GET_COURSES_MOBILE_PRICES({dispatch,rootState} ) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/iap/courses`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // SUPERADMIN


    async GET_BUSINESS_WITH_APPS({dispatch}  ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`mobile/active_businesses/select`,
                method:'POST',
            },{ root : true });
    }



}

export default {
    namespaced:true,
    actions
}